import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { borderTop, } from '@haaretz/htz-css-tools';
import H from '../AutoLevels/H';

const SectionTitleA = ({ title, isInMargin, id, }) => {
  const { css, theme, } = useFela();
  return (
    <H
      className={css({
        color: theme.color('layout', 'sectionTitleA'),
        fontWeight: 'bold',
        position: 'relative',
        paddingInlineStart: `${theme.layoutStyle.startColumnPadding}rem`,
        extend: [
          theme.mq({ until: 's', }, { paddingInlineStart: `${theme.layoutStyle.contPaddingS}rem`, }),
          theme.mq(
            { from: 'xl', },
            { paddingInlineStart: `${theme.layoutStyle.startColumnPaddingXL}rem`, }
          ),
          theme.type(theme.layoutStyle.sectionTitleATypeScaleFromL, { fromBp: 'l', }),
          theme.type(1, { untilBp: 'l', }),
          borderTop('2px', theme.layoutStyle.sectionTitleALines, 'solid', theme.color('layout', 'sectionTitleA')),
        ],
      })}
      id={id}
    >
      {isInMargin ? (
        <div
          className={css({
            extend: [
              theme.mq(
                { from: 'l', },
                {
                  position: 'absolute',
                  overflow: 'hidden',
                }
              ),
              theme.mq(
                { from: 'l', until: 'xl', },
                {
                  insetInlineStart: `${theme.layoutStyle.startColumnPadding}rem`,
                  maxWidth: `${theme.layoutStyle.startColumnWidthL
                    - theme.layoutStyle.startColumnPadding}rem`,
                }
              ),
              theme.mq(
                { from: 'xl', },
                {
                  insetInlineStart: `${theme.layoutStyle.startColumnPaddingXL}rem`,
                  maxWidth: `${theme.layoutStyle.startColumnWidthXL
                    - theme.layoutStyle.startColumnPaddingXL}rem`,
                }
              ),
            ],
          })}
        >
          <span>{title}</span>
        </div>
      ) : (
        title
      )}
    </H>
  );
};

SectionTitleA.propTypes = {
  title: PropTypes.string.isRequired,
  isInMargin: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

SectionTitleA.defaultProps = {};

export default SectionTitleA;
