import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { borderTop, parseStyleProps, } from '@haaretz/htz-css-tools';
import Section from '../AutoLevels/Section';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import usePreview from '../../hooks/Page/usePreview';

function WideArticleLayoutRow({ children, hideDivider, miscStyles, showBorderTop, }) {
  const { css, theme, } = useFela();
  
  const { isPreview, } = usePreview();
  if (isPreview) return null;

  return (
    <Section
      className={css({
        paddingTop: '3rem',
        extend: [
          showBorderTop
            ? borderTop('2px', 2, 'solid', hideDivider ? 'transparent' : theme.color('primary'))
            : {},
          theme.mq(
            { from: 'l', until: 'xl', },
            {
              paddingInlineStart: `${theme.layoutStyle.startColumnPadding}rem`,
            }
          ),
          theme.mq(
            { from: 'xl', },
            {
              paddingInlineStart: `${theme.layoutStyle.startColumnPaddingXL}rem`,
            }
          ),
          ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
        ],
      })}
    >
      {children}
    </Section>
  );
}

WideArticleLayoutRow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  hideDivider: PropTypes.bool,
  miscStyles: stylesPropType,
  showBorderTop: PropTypes.bool,
};

WideArticleLayoutRow.defaultProps = {
  hideDivider: false,
  miscStyles: null,
  showBorderTop: true,
};

export default WideArticleLayoutRow;
