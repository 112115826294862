/* global IntersectionObserver sessionStorage document window */
// @flow
import * as React from 'react';
import { useMutation, } from 'react-apollo';
import { useFela, } from 'react-fela';
import useArticleId from '../../hooks/Page/useArticleId';
import useDocumentEventListener from '../../hooks/useDocumentEventListener';
import useIsBlock from '../../hooks/useIsBlock';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useUserAgent from '../../hooks/useUseragent';
import { useEventTracker, } from '../../utils/EventTracker';
import UPDATE_ARTICLE from '../List/mutations/updatePersonalList.graphql';

type ArticleObserverProps = {
  breakpoints?: { breakpoint: string, shouldUpdate: boolean, }[],
  observerRef?: { current: null | HTMLElement, },
  articleHeadRef?: { current: null | HTMLElement, },
  observerSettingsFromProps?: { root: null | HTMLElement, threshold: number, },
};

ArticleObserver.defaultProps = {
  breakpoints: [],
  observerRef: { current: null, },
  articleHeadRef: { current: null, },
  observerSettingsFromProps: { root: null, threshold: 0, },
};

let globalObserver = null;

function ArticleObserver({
  breakpoints = [],
  observerRef,
  articleHeadRef,
  observerSettingsFromProps,
}: ArticleObserverProps): React.Node {
  const { biImpression, } = useEventTracker();
  const elRefs = React.useRef([]);
  const { css, } = useFela();
  const { isIPhone, } = useUserAgent();
  const scrollElementRef = React.useRef(null);
  const [ documentHeight, setDocumentHeight, ] = React.useState(null);
  const [ recommendationType, setRecommendationType, ] = React.useState(null);
  const [ startTime, ] = React.useState(Date.now());
  const articleId = useArticleId();
  const isBlock = useIsBlock();
  const isWebView = useWebViewChecker();
  const [ isLoaded, setIsLoaded, ] = React.useState(typeof window === 'undefined' ? false : window.deviceId !== undefined);
  const [ abData, setABData, ] = React.useState({
    abGroup: null,
    abTestGroupLabel: null,
    abTestName: null,
  });
  const [ updateHandled, setUpdateHandled, ] = React.useState(false);
  const [ lastTriggeredBreakpoint, setLastTriggeredBreakpoint, ] = React.useState(0);
  const [ updatePersonalQuery, ] = useMutation(UPDATE_ARTICLE);

  const onLoadElement = React.useCallback(() => {
    setIsLoaded(true);
  }, []);

  useDocumentEventListener('loadElement', onLoadElement, false);

  // The useLayoutEffect ensures that documentHeight is calculated after DOM updates,
  // using the references to articleHeadRef and observerRef.
  // It's triggered whenever these references or documentHeight itself change.
  React.useLayoutEffect(() => {
    const calculateHeight = () => {
      const accHeight = articleHeadRef.current?.offsetHeight
        ? observerRef.current?.offsetHeight + articleHeadRef.current?.offsetHeight
        : observerRef.current?.offsetHeight;
      setDocumentHeight(accHeight);
    };
    calculateHeight();
  }, [ articleHeadRef, observerRef, documentHeight, ]);

  // This listens for the load event to ensure that the height calculation is
  // performed after the content is fully loaded in the app.
  React.useEffect(() => {
    if ((isLoaded && isWebView) || !documentHeight) {
      const handleAppLoad = () => {
        const calculateHeight = () => {
          const accHeight = articleHeadRef.current?.offsetHeight
            ? observerRef.current?.offsetHeight + articleHeadRef.current?.offsetHeight
            : observerRef.current?.offsetHeight;
          setDocumentHeight(accHeight);
        };

        calculateHeight();
      };

      // Add event listener for the app load event
      window.addEventListener('load', handleAppLoad);
      return () => {
        window.removeEventListener('load', handleAppLoad);
      };
    }
    return () => { };
  }, [ isWebView, articleHeadRef, observerRef, documentHeight, isLoaded, ]);


  const handleShouldUpdate = React.useCallback(() => {
    if (articleId) {
      updatePersonalQuery({
        variables: {
          input: {
            readArticleId: articleId,
            viewedArticleIds: null,
          },
        },
      });
      setUpdateHandled(true);
    }
  }, [ updatePersonalQuery, articleId, ]);

  const observerCallback = React.useCallback(entries => {
    entries.forEach(({ target, isIntersecting, }) => {
      if (isIntersecting) {
        const breakpoint = parseFloat(target.getAttribute('data-breakpoint'));

        if (breakpoint > lastTriggeredBreakpoint) {
          setLastTriggeredBreakpoint(breakpoint);

          // Trigger shouldUpdate once per breakpoint
          if (!updateHandled) {
            const shouldUpdateNow = breakpoints.some(bp => bp.breakpoint <= breakpoint && bp.shouldUpdate);
            if (shouldUpdateNow) {
              handleShouldUpdate();
            }
          }

          const currentTime = Date.now();
          biImpression({
            feature: 'Scroll',
            featureType: 'Content',
            campaignName: `${breakpoint * 100}`,
            abGroup: abData.abGroup,
            abTestGroupLabel: abData.abTestGroupLabel,
            abTestName: abData.abTestName,
            additionalInfo: {
              ...recommendationType ? { origin: recommendationType, } : {},
              timeOnArticle: `${(currentTime - startTime) / 1000}`,
            },
          });

          const accHeight = articleHeadRef.current?.offsetHeight
            ? observerRef.current?.offsetHeight + articleHeadRef.current?.offsetHeight
            : observerRef.current?.offsetHeight;
          setDocumentHeight(accHeight);
        }
      }
    });
  }, [ lastTriggeredBreakpoint, updateHandled, biImpression, abData.abGroup, abData.abTestGroupLabel, abData.abTestName, recommendationType, startTime, articleHeadRef, observerRef, breakpoints, handleShouldUpdate, ]);

  React.useEffect(() => {
    if (documentHeight && (!isWebView || isLoaded)) {
      if (!globalObserver) {
        globalObserver = new IntersectionObserver(observerCallback, observerSettingsFromProps);
      }
      // Observe elements
      elRefs.current.forEach(el => {
        if (el) globalObserver.observe(el);
      });
    }

    // Cleanup function
    return () => {
      if (globalObserver) {
        globalObserver.disconnect();
        globalObserver = null;
      }
    };
  }, [ documentHeight, isWebView, isLoaded, observerCallback, observerSettingsFromProps, ]);


  // Refs setup for observing elements
  const refs = React.useCallback(
    (el, index) => {
      elRefs.current[index] = el;
      if (el && globalObserver) {
        globalObserver.observe(el);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ globalObserver, ]
  );

  React.useEffect(() => {
    const storedArticleId = sessionStorage.getItem('articleId');
    const storedListOrigin = sessionStorage.getItem('listOrigin');
    const abGroup = sessionStorage.getItem('ab_test_group');
    const abTestName = sessionStorage.getItem('ab_test_name');
    const abTestGroupLabel = sessionStorage.getItem('ab_test_group_label');

    if (storedArticleId === articleId) {
      const recommendation = storedListOrigin
        ? storedListOrigin === 'editors'
          ? 'Editors Pick'
          : storedListOrigin === 'similarArticle'
            ? 'Similar Article'
            : storedListOrigin === 'userHistory'
              ? 'User History'
              : storedListOrigin === 'TmBooster'
                ? 'TM Booster'
                : storedListOrigin === 'colFiltering'
                  ? 'Collaborative Filtering'
                  : storedListOrigin === 'joni'
                    ? 'Joni'
                    : storedListOrigin
        : null;
      setRecommendationType(recommendation);
      setABData({ abGroup, abTestGroupLabel, abTestName, });
    }

    sessionStorage.removeItem('articleId');
    sessionStorage.removeItem('listOrigin');
    sessionStorage.removeItem('ab_test_group');
    sessionStorage.removeItem('ab_test_name');
    sessionStorage.removeItem('ab_test_group_label');
  }, [ articleId, ]);

  React.useEffect(() => {
    if (isIPhone()) {
      const scrollElement = scrollElementRef?.current;
      if (scrollElement) {
        scrollElement.style.WebkitOverflowScrolling = 'touch';
      }
    }
  }, [ isIPhone, ]);

  const Breakpoints = React.useMemo(() => breakpoints.map((breakpoint, index) => {
    if (!documentHeight) return null;
    return (
      <div
        key={breakpoint.breakpoint}
        ref={el => refs(el, index)}
        data-breakpoint={breakpoint.breakpoint}
        className={css({
          position: 'absolute',
          top: `${breakpoint.breakpoint * documentHeight}px`,
        })}
      />
    );
  }), [ breakpoints, css, documentHeight, refs, ]);

  if (!documentHeight || isBlock) return null;

  return (
    <div ref={scrollElementRef}>
      {Breakpoints}
    </div>
  );
}

export default ArticleObserver;
